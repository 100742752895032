/**
 * Manager
 * @class Page Manager
 * @returns
 */
var Manager = Klass.create();

Manager.extend(JqManager);

//definition start
Manager.extend({
/**
 * init
 */
init:function(){
	this.timer = null;
	this.perf_no = 1;
	this.app_no = 1;
	this.anim = false;
	this.$document = $(document);
	this.$window = $(window);
	this.isScroll = false;
	this.isSearchBoxFocus = false;
	this.spBreakPoint = 768;
	this.spMode = false;
	this.headerIsWhite = false;

	// 下層 FV スライダー
	this.swiper_unv_fv;
	// TOP バナースライダー
	this.bnr;

	//TOPに戻るボタンフラグ
	this.isGotop = false;

},
/**
 * 設定
 */
set:function(){
	this.setParam();
	this.setObject();
},
/**
 * 開始
 */
start:function(){
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	oCom.call(this, this.setState, [],"");
	oCom.call(this, this.setEvent, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
/**
 * 開始後
 */
after:function(){
	var oAsync = this.getAsyncCommand(arguments);
	var oCom = new Command();
	//oCom.call(this, this.animAll, [],"");
	if(oAsync && oAsync.publish){ oCom.callback(oAsync,oAsync.publish,[]); }
	oCom.execute();
},
///////////////////////////////////////////////////////////////////////////////////
//setting
///////////////////////////////////////////////////////////////////////////////////
/**
 * パラメータセット
 */
setParam:function(){
	var oSelf = this;

	// IEの設定を生かす場合コメントアウトをとる
	// if(_cmn["ua"] === "ie" && ~~_cmn["ver"] === 8){
	// 	this.ie8 = true;
	// }
	// if(_cmn["ua"] !== "ie" || (_cmn["ua"] === "ie" && ~~_cmn["ver"] >= 10)){
	// 	this.modern = true;
	// }
},

/**
 * オブジェクト設定
 */
setObject:function(){
},
/**
 * 初期状態セット
 */
setState:function(){
	var oSelf = this;

	if(!_manager.ie8){
		if(_manager.modern){
			//modern
		}else{
			//ie9
		}
	}else{
		//ie8
	}
	this.resize(null,false);

	oSelf.ShowHideAccordion();

	if($(".header-is-white").length > 0){
		this.headerIsWhite = true;
	}

	// ローディングを非表示にする
	if($('#loading').length){
		$("#loading").addClass("display-none");
	};

	var $kv = $("#kv_video");
	if(this.spMode){
		$kv.attr("src", $kv.attr("sp_src"));
	}else{
		$kv.attr("src", $kv.attr("pc_src"));
	}

	var rellax = new Rellax('.parallax img',{
		center: true,
	});

	var componentX = new Swiper(".componentX", {
		slidesPerView: "auto",
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});

	if ( $(".bnr").length == 1 ) {
		oSelf.initBnrSwiper();
	}

	// 下層 FV のスライダー
	var swiper_unv_fv_options = {}; // オプションを指定する宣言
	if ( $(".swiper-unv_fv .swiper-slide").length == 1 ) {
		// スライドが1枚の時
		swiper_unv_fv_options = {
			slidesPerView: "1",
			effect: 'fade',
			loop: false
		}
	} else {
		// スライドが複数枚の時
		swiper_unv_fv_options = {
			slidesPerView: "1",
			effect: 'fade',
			loop: false, // ループ true にすると「→」ボタンでのスライド遷移が機能しなくなる
			autoplay: {
				delay: 3000, // 切り替えの間隔
				disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
			},
			speed: 1500, // フェード時のスピード
			pagination: { // 0付き数字のページネーション
				el: '.swiper-pagination',
				type: 'fraction',
				formatFractionCurrent: function (number) {
					if (number < 10) {
						return '0' + number;
					} else {
						return number;
					}
				},
				formatFractionTotal: function (number) {
					if (number < 10) {
						return '0' + number;
					} else {
						return number;
					}
				}
			},
		}
		// 「→」ボタン追加
		var next_element = document.createElement('div');
		next_element.classList.add("next");
		$(".swiper-unv_fv").append(next_element);
	}
	this.swiper_unv_fv = new Swiper('.swiper-unv_fv', swiper_unv_fv_options);

	// 下層「○○学部を知る」のスライダー
	var swiper_unv_know = new Swiper(".swiper-unv_know", {
		slidesPerView: "auto",
	});

	// SP時の、学科のタブの幅を設定
	this.setDepartmentTabWidth();

	// お知らせ一覧ページャー
	if($('.news-wrapper').length){
		$('.news-wrapper').pagination({
			element      :'li',
			prevText     : '',
			nextText     : '',
			firstText    : '',
			lastText     : '',
			ellipsisText : '',
			viewNum      : 20, // 1ページあたりの表示件数
			pagerNum     : 2, // ... の左側に表示する数字の個数 // 1にすると .current が最初と最後の数字以外につかないバグがある
			ellipsis     : true,
			linkInvalid  : true,
			goTop        : false, // 数字をクリックしたときに、ページの先頭に移動するかどうか
			firstLastNav : false,
			prevNextNav  : true
		});
	}

	// jwutimes一覧ページャー
	if($('.jwutimes_list').length){
		$('.jwutimes_list').pagination({
			element      :'li',
			prevText     : '',
			nextText     : '',
			firstText    : '',
			lastText     : '',
			ellipsisText : '',
			viewNum      : 21, // 1ページあたりの表示件数
			pagerNum     : 2, // ... の左側に表示する数字の個数 // 1にすると .current が最初と最後の数字以外につかないバグがある
			ellipsis     : true,
			linkInvalid  : true,
			goTop        : false, // 数字をクリックしたときに、ページの先頭に移動するかどうか
			firstLastNav : false,
			prevNextNav  : true
		});
	}

	// バイリンガル一覧ページャー
	if($('.bilingual_list').length){
		$('.bilingual_list').pagination({
			element      :'li',
			prevText     : '',
			nextText     : '',
			firstText    : '',
			lastText     : '',
			ellipsisText : '',
			viewNum      : 21, // 1ページあたりの表示件数
			pagerNum     : 2, // ... の左側に表示する数字の個数 // 1にすると .current が最初と最後の数字以外につかないバグがある
			ellipsis     : true,
			linkInvalid  : true,
			goTop        : false, // 数字をクリックしたときに、ページの先頭に移動するかどうか
			firstLastNav : false,
			prevNextNav  : true
		});
	}

	// コンポーネント６
	this.setComponent6ImgHeight();
},
/**
 * setEvent
 */
setEvent:function(){
	var oSelf = this;

	//cmn
	this.setWayPoint();
	this.setResize();

	//aタグ#指定位置にスクロール移動
	_cmn.setScroll();

	var timeoutId;

	scrollTop = $(window).scrollTop();
	if(scrollTop == 0){
		$("header").addClass("top");
		if(oSelf.headerIsWhite){
			$("header").addClass("white");
			$(".logo-area h1").find("img").attr("src",logoW);
			$(".open_btn").attr("src",menuOpenW);
			var windowWidth = $(window).width();
			var windowSm = 768;
			if (windowWidth <= windowSm) {
				//横幅768px以下（スマホ）に適用させるJavaScriptを記述
				$(".search").find("img").attr("src",searchSPW);
			} else {
				//横幅768px以上（PC、タブレット）に適用させるJavaScriptを記述
				$(".search").find("img").attr("src",searchW);
			}
		}
	}else{
		$("header").addClass("under");
		if(oSelf.headerIsWhite){
			$("header").removeClass("white");
			$(".logo-area h1").find("img").attr("src",logo);
			$(".open_btn").attr("src",menuOpen);
			var windowWidth = $(window).width();
			var windowSm = 768;
			if (windowWidth <= windowSm) {
				//横幅768px以下（スマホ）に適用させるJavaScriptを記述
				$(".search").find("img").attr("src",searchSP);
			} else {
				//横幅768px以上（PC、タブレット）に適用させるJavaScriptを記述
				$(".search").find("img").attr("src",search);
			}
		}
	}

	// スマフォの場合はタッチ操作で検索窓を消す
	var preY = 0;
	document.addEventListener("touchstart", function(e){
		preY = e.touches[0].clientY;
	});
	document.addEventListener("touchmove", function(e){
		var nowY = e.touches[0].clientY;
		var moveY = Math.abs(preY - nowY);
		if(moveY > 20){
			if(oSelf.spMode && !$("#search-box").hasClass("hidden")){
				$("#search-box").addClass("hidden");
			}
		}
	});
	window.addEventListener("scroll", function(e){
		oSelf.isScroll = true;
		scrollTop = $(window).scrollTop();

		$("header").addClass("scroll");

		if(!$("#search-box").hasClass("hidden") && !oSelf.isSearchBoxFocus){
			$("#search-box").addClass("hidden");
		}

		// スクロールを停止して500ms後に終了とする
		clearTimeout( timeoutId ) ;

		timeoutId = setTimeout( function () {
			oSelf.isScroll = false;

			$("header").removeClass("scroll");
			var windowWidth = $(window).width();
			var windowSm = 768;
			if(scrollTop == 0){
				$("header").removeClass("under");
				$("#search-box").removeClass("under");

				if(oSelf.headerIsWhite){
					$("header").addClass("white");
					$(".logo-area h1").find("img").attr("src",logoW);
					$(".open_btn").attr("src",menuOpenW);

					if (windowWidth <= windowSm) {
						//横幅768px以下（スマホ）に適用させるJavaScriptを記述
						$(".search").find("img").attr("src",searchSPW);
					} else {
						//横幅768px以上（PC、タブレット）に適用させるJavaScriptを記述
						$(".search").find("img").attr("src",searchW);
					}
				}
			}else{
				$("header").addClass("under");
				$("#search-box").addClass("under");

				if(oSelf.headerIsWhite){
					$("header").removeClass("white");
					$(".logo-area h1").find("img").attr("src",logo);
					$(".open_btn").attr("src",menuOpen);

					if (windowWidth <= windowSm) {
						//横幅768px以下（スマホ）に適用させるJavaScriptを記述
						$(".search").find("img").attr("src",searchSP);
					} else {
						//横幅768px以上（PC、タブレット）に適用させるJavaScriptを記述
						$(".search").find("img").attr("src",search);
					}
				}
			}
		}, 500 ) ;

		//TOPに戻るボタン非表示処理（表示処理は611行目）
		if(scrollTop == 0){
			$(".totop").removeClass("view");
			$(".open-canvas").addClass("view");
			$(".fixed-bnr").addClass("view");
		}
	});

	// バナーにホバーしている時は、スライダーの動きを停止する
	$(".bnr .swiper-wrapper .swiper-slide").hover(
		function (){
			// 要素にマウスを載せたときの処理
			oSelf.bnr.autoplay.stop();
		},
		function () {
			// 要素からマウスをはなしたときの処理
			oSelf.bnr.autoplay.start();
		}
	);

	// 検索にフォーカスしてキーボード開いた時に、検索窓が消えるのを防ぐ
	$("#cse-search-box").on("focusin", function(e) {
		oSelf.isSearchBoxFocus = true;
	});
	$("#cse-search-box").on("focusout", function(e) {
		oSelf.isSearchBoxFocus = false;
	});

	$("#menu_btn").on("click" , function(e){
		var isClose = $("header").hasClass("hidden");
		if(isClose){
			$("header").removeClass("hidden");
			$("body").css({
				"overflow-y": "hidden"
			})
			calcHeaderHeight();
		}else{
			$("header").addClass("hidden");
			$("body").css({
				"overflow-y": "auto"
			})
		}
		$("header").animate({
			"scrollTop":0
		} , 0);


		//下層時のロゴ画像切り替え(#menu_btnのクリックイベントに記述)
		var isunder = $("header").hasClass("white");
		if(isunder){
			if(isClose){
				$(".logo-area h1").find("img").attr("src",logo);
			}else{
				$(".logo-area h1").find("img").attr("src",logoW);
			}
		}
	});

	$("#search--open--btn").on("click", function(){
		if($("#search-box").hasClass("hidden")){
			$("#search-box").removeClass("hidden");
		}else{
			$("#search-box").addClass("hidden");
		}

	});

	$("#notices-area-close").on("click", function(){
		$noticesArea = $(".notices-area");
		$noticesArea.remove();
		oSelf.resize(null,false);
		$("header").on('transitionend', function() {
			calcHeaderHeight();
		});
	})

	$(".pulldown > a").on("click", function(e){
		var isOpen = $(this).parent().hasClass("open");

		if(oSelf.spMode) {
			$(this).parent().find(".under").slideToggle();
		}
		$(this).parent().toggleClass("open");
	})

	/**
	 * 下層 FV スライダー右下にある「→」ボタンクリック時の処理
	 * デフォルトの .swiper-button-next を使用したかったけど、
	 * ループできず最後のスライドで非活性になったりするので、自前で実装
	 */
	if($('.next').length && $(".underfv").length){ // next だけで判定すると、ページャーとバッティングする
		$oSelf = this;
		this.swiper_unv_fv.el.querySelector('.next').addEventListener('click', function() {
			if ($oSelf.swiper_unv_fv.isEnd) {
				$oSelf.swiper_unv_fv.slideTo(0);
			} else {
				$oSelf.swiper_unv_fv.slideTo($oSelf.swiper_unv_fv.realIndex + 1);
			}
		});
	}

	// 学部ページ「卒業生のしごと」タブ切替
	var $tab = $(".tab-list-ul > li");
	$tab.on("click" , function(e){
		// タブの見た目切替
		$tab.find("p").removeClass("active");
		$(this).find("p").addClass("active");

		// 情報の切替
		var index = $tab.index(this);
		$(".data-ul > li").removeClass("active");
		$(".data-ul > li").eq(index).addClass("active");
	});


	//オープンキャンパス情報ページのアコーディオン（355行目まで）
	$(".accordion_ttl").on("click", function() {
		var $this = $(this).parent();
		accordion($this);
	});
	$(window).bind("resize",function(e){
		// リサイズ時に高さが変わるため、再設定する
		setContentsHeight();
		calcHeaderHeight();
		oSelf.ShowHideAccordion();
		if(!oSelf.spMode) {
			// メニュー開いたまま PC 用に変わってしまうと hidden になっているため画面がスクロールできないのを防止する
			$("body").css("overflow-y", "auto");
		}
	});

	// --------------------------------------
	// MENU の height を、重要なお知らせエリアを除いた高さに設定する。（SP 時のみ）
	// --------------------------------------
	function calcHeaderHeight(){
		if(oSelf.spMode) {
			var windowHeight = window.innerHeight;
			var headerHeight;

			if($(".notices-area").length) {
				var noticeHeight = $(".notices-area").height();
				headerHeight = windowHeight - noticeHeight;
			} else {
				headerHeight = windowHeight;
			}

			$("header").css("height", headerHeight);
		} else {
			// ブラウザ幅を可変させて SP から PC に切り替わった際に、SPで設定したヘッダーの高さが受け継がれてしまわないよう、
			// JS で設定した値を削除し、CSSで設定してある高さを適用する。
			$("header").css("height", "");
		}

	}

	// --------------------------------------
	// 隠していた要素の高さを文字数に応じて可変させる
	// --------------------------------------
	function setContentsHeight(){
		if($(".accordion_contents").length) {
			$(".accordion_contents").each(function() {
				// 要素の高さを取得し、cssに追加
				// paddingを含んだ高さを取得する場合は、 height ではなく outerHeight を使用
				var this_hide_contents_height = $(this).find(".accordion_inner").outerHeight();
				$(this).css('height',this_hide_contents_height);
			});
		}
		if($(".menu_item_wrap").length) {
			$(".menu_item_wrap").each(function() {
				// 要素の高さを取得し、cssに追加
				// paddingを含んだ高さを取得する場合は、 height ではなく outerHeight を使用
				var this_hide_contents_height = $(this).find(".accordion_inner").outerHeight();
				$(this).css('height',this_hide_contents_height);
			});
		}
	}

	// --------------------------------------
	// 隠していた要素を表示/非表示
	// --------------------------------------
	function accordion($this){
		// 隠している状態を設定しているクラス
		var hide_class = "hide";

		// 隠していた要素の高さを取得し、cssに追加
		setContentsHeight();

		// hide_class がついていれば remove して要素を表示、
		// ついていなければ add して非表示にする
		if($this.hasClass(hide_class)) {
			$this.removeClass(hide_class);
		} else {
			$this.addClass(hide_class);
		}
	}

	//動画modal開閉（component11-modal）
	var open = $('.modal-open');
	var close = $('.modal-close');
	var container = $('.component11-modal');
	open.on('click',function(e){
		//aタグのデフォルトの動作をキャンセル
		e.preventDefault();

		//動画ID取得
		var movId = $(this).attr("data-id");
		var $mov = $('<iframe src="https://www.youtube.com/embed/'+ movId +'?rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');

		//iframeを挿入
		container.find(".modal-content").append($mov);
		container.css("display","flex");

		//iframeを挿入後にモーダル表示
		setTimeout(function(){
			container.addClass('active');
		},100);
	});

	close.on('click',function(){
		container.removeClass('active');
		// $mov.remove();
		container.find(".modal-content").empty();
		setTimeout(function(){
			container.css("display","none");
		},100);
	});

	//テキストと画像のモーダル開閉(component22)
	$(".text-modal-open").on('click',function(e){
		//aタグのデフォルトの動作をキャンセル
		e.preventDefault();

		var modalId = $(this).attr("data-id");
		$('#' + modalId + '').css("display","flex");

		setTimeout(function(){
			$('#' + modalId + '').addClass('active');
		},100);
	});

	$(".component22").find(".modal-close").on('click',function(e){
		$(".component22").removeClass('active');
		setTimeout(function(){
			$(".component22").css("display","none");
		},100);
	});

},
/**
 * スクロール位置ベースのイベント
 */
setWayPoint:function(){
	$("h2").each(function(){
		var $node = $(this).find("span");
		var counter = 0;
		$node.each(function(){
			var txt = $(this).text();
			var html = "";
			for(var i = 0; i < txt.length; i++){
				html += "<span class='effect'>";
				html += txt[i] + "</span>";
			}
			var defaultDelay = 0.2;
			var delayOffset = 0.02;
			html = $(html);
			html.each(function(){
				var delay = defaultDelay + (delayOffset * counter);
				$(this).css({
					"transition-delay": delay + "s"
				})
				counter++;
			})
			$(this).html(html);
		});
	});

	$(".waypoint").addClass("hidden");
	$(".waypoint").waypoint(function(direction){
		$(this).addClass("view");
		$(this).removeClass("hidden");

		// TOP ボタンの表示切り替えをするために .news-wrapper にも .waypoint を付与している
		if($(".news-wrapper").length) {
			$(".news-wrapper").addClass("view");
			$(".news-wrapper").removeClass("hidden");
		}

		//TOPに戻るボタン表示処理（非表示は362行目）
		if(!this.isGotop){
			$(".totop").addClass("view");
			$(".open-canvas").removeClass("view");
			$(".fixed-bnr").removeClass("view");
		}
	},{offset:"70%"});

	//下層ページFV時のheader見た目切り替え
	// $(".nav").waypoint(function(direction){
	// 	if (direction == 'down') {
	// 		$("header").removeClass("white");
	// 		$(".search").find("img").attr("src","/unv/assetss/img/common/search.svg");
	// 		$(".logo-area h1").find("img").attr("src","/unv/assetss/img/common/logo.png");
	// 		$(".open_btn").attr("src","/unv/assetss/img/common/menu_open.svg");
	// 	}else{
	// 		$("header").addClass("white");
	// 		$(".search").find("img").attr("src","/unv/assetss/img/common/search_w.svg");
	// 		$(".logo-area h1").find("img").attr("src","/unv/assetss/img/common/logo_w.png");
	// 		$(".open_btn").attr("src","/unv/assetss/img/common/menu_open_w.svg");
	// 	}
	// },{offset:"10%"});
},
/**
 * リサイズイベントセット
 */
setResize:function(){
	var oSelf = this;

	$(window).bind("resize",function(e){
		_manager.resize(e,true);

		// SP時の、学科のタブの幅を設定
		oSelf.setDepartmentTabWidth();
		// コンポーネント６
		oSelf.setComponent6ImgHeight();
	});
},
///////////////////////////////////////////////////////////////////////////////////
//event
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
//function
///////////////////////////////////////////////////////////////////////////////////
setDepartmentTabWidth:function(){
	// 学科のタブの幅を設定（SP で横スクロールするため）
	var windowWidth = $(window).width();
	var windowSm = 768;
	if ( $(".department_detail").length) {
		//横幅768px以下（スマホ）に適用させるJavaScriptを記述
		if(windowWidth <= windowSm) {
			var ul_width = 0;

			$(".tab-list-ul li").each(function(i, elem){
				ul_width += $(elem).innerWidth();
			});
			// 幅がピッタリすぎると、段落ちしてしまうことがあるので 1px 追加
			ul_width += 2;

			$(".tab-list-ul").css("width", ul_width);
		} else {
			$(".tab-list-ul").css("width", "auto");
		}

	}

},
setComponent6ImgHeight:function(){
	// コンポーネント６の absolute 画像に、
	// どれだけ縦長の画像が入ってもその下の要素にかぶらない
	//（ちゃんと高さを指定できる）ようにする

	if ( $(".component6").length) {
		var windowWidth = $(window).width();
		var windowSm = 768;

		if(windowWidth <= windowSm) {
			//横幅768px以下（スマホ）に適用させるJavaScriptを記述
			$(".component6").each(function(i, elem){
				$(elem).css("height", "auto");
			});
		} else {
			// PC
			$(".component6").each(function(i, elem){
				//画像の高さを取得
				var $img = $(elem).find($(".img"));
				var img_height = $img.height();

				// テキストの高さを取得
				var $text = $(elem).find($(".text"));
				var text_height = $text.outerHeight(); // paddingを含むため

				// テキストよりも画像の方が高さがあれば、
				// 画像（elem）の親要素に高さを設定する
				if(img_height > text_height){
					$(elem).css("height", img_height);
				}
			});
		}
	}
},
// --------------------------------------
// MENU内のアコーディオンの表示切り替え
// --------------------------------------
ShowHideAccordion:function(){
	if(this.spMode) {
		// MENU内のアコーディオンは、最初は非表示にしておく
		// .under.group-list はアコーディオンではないので隠さない
		$(".pulldown > .under.faculty").hide();
		$(".pulldown > .under.canvas-life").hide();
		$(".pulldown > .under.correspondence").hide();
	} else {
		// display: flex; をCSSで設定済なので、.show() ではなく値の削除にしている
		$(".pulldown > .under.faculty").css("display", "");
		$(".pulldown > .under.canvas-life").css("display", "");
		$(".pulldown > .under.correspondence").css("display", "");
	}
},
// --------------------------------------
// お知らせのバナーエリアの見た目切替
// --------------------------------------
initBnrSwiper:function(){
	var swiperOptions;

	var speed = 1000;
	var delay = 2000;
	var isDisableOnInteraction = false;
	var isClickable = true;
	var elClass = ".swiper-pagination";

	if(this.spMode) { // SP
		swiperOptions = {
            speed: speed,
            slidesPerView: 1,
            grid: {
                rows: 2,
            },
            autoplay: {
                delay: delay,
                disableOnInteraction: isDisableOnInteraction,
            },
            pagination: {
                el: elClass,
                clickable: isClickable,
            },
        };
	} else { // PC
		swiperOptions = {
            speed: speed,
			slidesPerView: 3,
			autoplay: {
				delay: delay,
				disableOnInteraction: isDisableOnInteraction,
			},
			pagination: {
				el: elClass,
				clickable: isClickable,
			},
			navigation: {
				nextEl: '.swiper-button-next', // 次へボタンのセレクタ
				prevEl: '.swiper-button-prev', // 前へボタンのセレクタ
			},
			breakpoints: {
				// スライド間の余白を指定（px）。px なのでマージンがズレないよう、細かく指定している
				// when window width is >= 768px
				768: {
					spaceBetween: 20
				},
				1000: {
					spaceBetween: 25
				},
				1200: {
					spaceBetween: 30
				},
				1360: {
					spaceBetween: 35
				},
				1670: {
					spaceBetween: 45
				}
			}
        };
	}

	// Swiperを初期化
    if (typeof this.bnr !== "undefined") {
        this.bnr.destroy();
    }
	this.bnr = new Swiper(".bnr-inner", swiperOptions);
},
///////////////////////////////////////////////////////////////////////////////////
//util
///////////////////////////////////////////////////////////////////////////////////
/**
 * resize
 */
resize:function(e,b){
	var oSelf = this;

	var $window = $(window);
	var iWindowW = window.innerWidth;
	var iWindowH = window.innerHeight;
	this.spMode;

	var $kv = $("#kv_video");

	if(iWindowW < this.spBreakPoint){
		if(!this.spMode){
			this.spMode = true;
			if($kv.length){
				$kv.attr("src", $kv.attr("sp_src"));
			}
		}
	}else{
		if(this.spMode){
			this.spMode = false;
			if($kv.length){
				$kv.attr("src", $kv.attr("pc_src"));
			}
		}
	}

	oSelf.initBnrSwiper();

	var $noticesArea = $(".notices-area");
	if($noticesArea.length){
		var margin = $noticesArea.outerHeight();
		$("header").css("top", margin + "px");
		$("#kv_video").css("top", margin + "px");
		$(".main").css("top", margin + "px");
		$(".cv-area").css("top", margin + "px");
		$(".open-canvas").css("top", margin + "px");
		$(".fixed-bnr").css("top", margin + "px");
		$(".search-box").css("top", margin + "px");
	}else{
		$("header").css("top", "0");
		$("#kv_video").css("top", "0");
		$(".main").css("top", "0");
		$(".cv-area").css("top", "0");
		$(".open-canvas").css("top", "0");
		$(".fixed-bnr").css("top", "0");
		$(".search-box").css("top", "0");
	}
}
});
//definition end