$(function(){
    // var headH = $("header").outerHeight();
    // var animeSpeed = 500;
    // var urlHash = location.hash; //URLのハッシュタグを取得
    // if (urlHash) { //ハッシュタグが有る場合
    //     $("body,html").scrollTop(0);
    //     setTimeout(function () { //無くてもいいが有ると動作が安定する
    //         var target = $(urlHash);
    //         var position = target.offset().top - headH;
    //         $("body,html").stop().animate({
    //             scrollTop: position
    //         }, animeSpeed);
    //     }, 0);
    // }
});

window.onload = function(){
	if(!_cmn){
		_cmn = CmnManager.create();
		_cmn.set();
		_cmn.start();
    }
}
/*
// IFrame Player API の読み込みタグを挿入
var tag = document.createElement('script') // scriptタグを生成
tag.src = "https://www.youtube.com/iframe_api"  // APIのURLを付与
var firstScriptTag = document.getElementsByTagName('script')[0] // 生成したタグをセット
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag) // HTML上に挿入

var player // 変数playerを用意
function onYouTubeIframeAPIReady() { // APIが読み込まれて準備が整ったら実行される関数
  player = new YT.Player('kv_video', { // YT.Playerオブジェクトを作成（'player'は動画の挿入先のid名）
    videoId: 'fxhaMEvPMVQ',
    playerVars: {
      // 'autoplay': 1,
      'mute': 1,
      'controls': 0,
      'loop': 1,
      'playlist': 'fxhaMEvPMVQ',
      'rel': 0,
	  'playsinline': 1
    },
	events: {
		onReady: onPlayerReady,
		onStateChange: onPlayerStateChange
	}
  })
}
function onPlayerReady (event) {
	event.target.mute()
	event.target.playVideo()
}
  function onPlayerStateChange (event) {
	if (event.data === YT.PlayerState.ENDED) {
	  event.target.playVideo()
	}
}
*/